<template>
    <div class="faq">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">系统帮助</div>
        <div class="card">
            <van-collapse v-model="activeNames">
            <van-collapse-item name="1">
            <template #title>
                <div class="title flex">
                    <img src="../../assets/img/wenhao.png" alt="">
                    <p>系统提供哪些服务？</p>
                </div>
            </template>
            <div class="content">
                1. 信贷申请<br/>2. 基本户开户预约<br/>3. 金融宣传、资料下载等
            </div>
            </van-collapse-item>
            <van-collapse-item name="2">
                <template #title>
                <div class="title flex">
                    <img src="../../assets/img/wenhao.png" alt="">
                    <p>如何注册用户？用户注册和使用是免费的吗？</p>
                </div>
                </template>
                <div class="content">
                    1. 注册方式有两种：在首页顶部点击“注册”；在登入窗口，点击“马上注册”<br/>2. 融资对接平台系统是为提高金融服务质量而建立的综合性金融服务平台，目前对企业用户注册和使用不收取任何费用.
                </div>
            </van-collapse-item>
            <van-collapse-item ame="3">
                <template #title>
                <div class="title flex">
                    <img src="../../assets/img/wenhao.png" alt="">
                    <p>系统网站上都有哪些银行的信贷产品?</p>
                </div>
                </template>
                <div class="content">
                    1. 在系统运行过程中，银行机构和信贷产品的数量都将根据实际情况而相应调整.
                </div>
            </van-collapse-item>
            <van-collapse-item ame="4">
                <template #title>
                <div class="title flex">
                    <img src="../../assets/img/wenhao.png" alt="">
                    <p>如何选择合适的信贷产品并提出贷款申请?</p>
                </div>
                </template>
                <div class="content">
                    1. 为了便于您查找适合自己的信贷产品，系统提供了淘宝式的信贷产品的筛选功能.<br/>
2. 在各地市网站首页点击“信贷产品展示与申请”栏目，可以看到该城市所有银行上传的信贷产品，并且可以根据自己需要按条件进行单独或组合查询.<br/>
3. 您可以对搜索到的产品每次选择2-3个进行比较，只要在需要比较的产品前勾选“加入选择栏”即可马上对比，产品信息的差异一目了然<br/>
4. 当您选择到合适的信贷产品后，点击“马上申请”就可进入贷款申请界面，只要您填写完善相关资料并发出贷款申请，就可能从银行获得融资
                </div>
            </van-collapse-item>
            <van-collapse-item ame="5">
                <template #title>
                <div class="title flex">
                    <img src="../../assets/img/wenhao.png" alt="">
                    <p>如何直接向银行机构提出贷款申请?</p>
                </div>
                </template>
                <div class="content">
                    1. 用户除了按照自己选择的银行信贷产品向相应银行机构提出贷款申请外，还可登录后进入操作菜单，向指定或不指定的所有银行机构（包括各支行）提出贷款申请.<br/>
2. 当用户选择向指定银行机构提出信贷申请时，每次只能向3个银行机构提出申请，指定的银行机构将在3~6个工作日内回复初步审核的结果.<br/>
3. 当用户选择向不指定银行机构发送信贷申请时，若用户选择将信贷申请对公众公开，则该申请信息将展示在系统网页上。所有浏览系统的用户均能看到该申请信息；若用户选择不对公众公开，则该申请信息仅供登录系统的银行机构人员能看到，银行机构将会联系合适的用户.
                </div>
            </van-collapse-item>
            <van-collapse-item ame="6">
                <template #title>
                <div class="title flex">
                    <img src="../../assets/img/wenhao.png" alt="">
                    <p>如何及时了解银行机构的贷款申请审批进度进展情况?</p>
                </div>
                </template>
                <div class="content">
                    1. 系统在技术上已经设置了督促功能，银行机构必须在3-6个工作日完成用户贷款申请的审核，并且会通过手机短信告知用户审批结果。用户也可登录系统查询审批进展情况.
                </div>
            </van-collapse-item>
            <van-collapse-item ame="6">
                <template #title>
                <div class="title flex">
                    <img src="../../assets/img/wenhao.png" alt="">
                    <p>使用本系统应该遵守哪些规定?</p>
                </div>
                </template>
                <div class="content">
                    1. 用户在注册和使用融资对接平台系统的过程中，务必点击各地市网站首页的“政策法规”，认证阅读并且遵守相关规定.
                </div>
            </van-collapse-item>
            </van-collapse>
        </div>


    </div>
</template>
<script>
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import { ref } from 'vue';
export default {
    setup() {
        let router = useRouter();
        let store = useStore();
        const activeNames = ref(['1']);
        const goBack = () =>{
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.back.postMessage(null);
          } catch (e) {
            alert('跳转返回ios失败');
          }
        }else {
            router.push({
                name: 'User'
            })
        }
        }
        return {
            activeNames,
            goBack
        }
    },

}
</script>
<style lang="less" scoped>
.faq {
    padding-top: 12.5vw;    
    .card {
        padding: 4vw 0;
        .title {
            img {
                width: 5vw;
                height: 5vw;
                margin-right: 2vw;
            }
            p {
                font-size: 4vw;
            }
        }
    }
}
</style>
<style lang="less">
.faq .van-collapse-item__content {
    font-size: 4vw;
    margin: 0 4vw;
    padding: 3vw;
    background-color: #eff5fd;
    border-radius: 1.5vw;
}
</style>